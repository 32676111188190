/* category.css */

/* .container {
    margin-top: 2rem;
    background-color: #f0f4f8; 
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
} */

.add-category-btn {
    margin-bottom: 1.5rem;
    background-color: #007bff; /* Primary color for button */
    color: #fff;
    border: none;
}

.add-category-btn:hover {
    background-color: #0056b3; /* Darker shade for hover effect */
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.image-preview {
    max-width: 200px;
    max-height: 200px;
    overflow: hidden;
    margin-bottom: 1rem;
    border: 1px solid #ddd; /* Light border for image preview */
    border-radius: 8px;
}

.image-preview img {
    width: 100%;
    height: 100px;
    object-fit: cover;
}

.table-responsive {
    background-color: #191c24; /* White background for table */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.table th, .table td {
    vertical-align: middle;
}

.table th {
    background-color: #e1e5e9; /* Primary color for table header */
    color: #fff;
    border: none;
}

.table tbody tr {
    transition: background-color 0.3s ease; /* Smooth transition for row hover */
}

.table tbody tr:hover {
    background-color: #f1f1f1; /* Light grey background on hover */
}

.table-img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
}

.category {
    margin-top: 100px;
}

/* CSS for underline effect with transition on hover */
td.category-name:hover {
    text-decoration: underline;
    transition: text-decoration 0.3s ease; /* Adjust timing and easing function as needed */
}

/* Button styles */
button.btn-warning, button.btn-danger {
    color: #fff;
}

button.btn-warning {
    background-color: #ffc107; /* Warning button color */
    border: none;
}

button.btn-warning:hover {
    background-color: #e0a800; /* Darker shade for hover effect */
}

button.btn-danger {
    background-color: #dc3545; /* Danger button color */
    border: none;
}

button.btn-danger:hover {
    background-color: #c82333; /* Darker shade for hover effect */
}

/* Modal buttons */
button[type="submit"] {
    background-color: #28a745; /* Success color for submit button */
    border: none;
    color: #fff;
}

button[type="submit"]:hover {
    background-color: #218838; /* Darker shade for hover effect */
}

button.ms-2 {
    background-color: #6c757d; /* Secondary color for close button */
    border: none;
    color: #fff;
}

button.ms-2:hover {
    background-color: #5a6268; /* Darker shade for hover effect */
}
.modal-dialog {
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: calc(100vh - 100px); /* Adjust the max-height as needed */
}
