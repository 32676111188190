/* subcategory.css */

/* Container for the entire subcategory component */
.subcategory-container {
    margin-top: 20px;
}

/* Button for adding a new subcategory */
.add-button {
    margin-bottom: 20px;
}

/* Custom modal style */
.custom-modal {
    /* Add your modal styles here */
}

/* Style for the preview image */
.preview-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-top: 10px;
}

/* Style for the submit button */
.submit-button {
    margin-top: 20px;
}

/* Style for the subcategory table */

/* Style for the table wrapper */
.table-wrapper {
    overflow-x: auto;
}

/* Style for the table */
.table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

/* Style for table header */
.table th {
    padding: 8px;
    text-align: left;
    background-color: #f2f2f2;
    border-bottom: 1px solid #ddd;
}

/* Style for table cells */
.table td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

/* Style for table image */
.table-image {
    width: 50px!important;

}
