/* Ensures the banner container is scrollable if content overflows */
.add-banner-container {
  margin-top: 80px;
  padding: 20px;
  background-color: #000000;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
  min-height: 100vh; /* Ensures the container takes at least the full viewport height */
  overflow: hidden; /* Hides overflow to manage scrolling manually */
  display: flex;
  flex-direction: column;
}



.add-banner-container form {
  margin-top: 20px;
}

.add-banner-container .form-group {
  margin-bottom: 20px;
}

.add-banner-container .form-control {
  border-radius: 5px;
}

.add-banner-container .image-preview {
  max-width: 100%; /* Adjusts to the width of the container */
  max-height: 300px; /* Adjust as needed */
  object-fit: contain; /* Ensures the aspect ratio is preserved */
  display: block;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.add-banner-container .video-preview {
  max-width: 100%; /* Adjusts to the width of the container */
  max-height: 300px; /* Adjust as needed */
  object-fit: contain; /* Ensures the aspect ratio is preserved */
}

.add-banner-container .btn-primary {
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
}

.add-banner-container .btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.preview-container {
  max-height: 400px; /* Adjust as needed */
  overflow-y: auto; /* Adds vertical scrolling */
}
/* Scoped styles for the AddBanner component */
.add-banner-container .card-body {
  flex: 1;
  overflow-y: auto; /* Allows vertical scrolling for the card body */
  max-height: calc(100vh - 140px); /* Adjust to account for margins and padding */
}

.add-banner-container .preview-container { 
  max-height: 400px; /* Adjust as needed */
  overflow-y: auto; /* Adds vertical scrolling */
}

/* Ensure the styles below are scoped specifically to this component */


