/* addproduct.css */

.addproduct-container {
    /* margin-top: 4rem; */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.addproduct h2 {

    font-family: Arial, sans-serif;
    font-size: 24px;
    font-weight: 500;
    color: #6c7293;
    margin-top: 19px;
}

.addproduct-card {
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

.addproduct-card .card-body {
    padding: 0;
}

.addproduct .form-control {
    border-radius: 5px;
    color: white;
}

.addproduct .form-control:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
}

.addproduct .form-label {
    color: #f4f4f4;
    
}

.addproduct .btn-primary {
    background-color: #007bff;
    border: none;
}

.addproduct .btn-primary:hover {
    background-color: #0056b3;
}

.addproduct .btn-secondary {
    background-color: #6c757d;
    border: none;
    color: #fff;
}

.addproduct .btn-secondary:hover {
    background-color: #5a6268;
}

.addproduct .card-header {
    border-bottom: 1px solid #e9ecef;
    padding: 10px 15px;
}

.addproduct .card-title {
    color: #007bff;
}

.image-upload {
    display: none;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    border-radius: 5px;
    
   
}

.js--image-preview img {
    max-width: 100%;
    max-height: 200px;
    object-fit: cover;
    border-radius: 5px;
}

.uploadedimage {
    margin-top: 10px;
}

.uploadedimage p {
    font-size: 14px;
    color: #333;
}

.uploadedimage img {
    max-width: 100px;
    max-height: 100px;
    border-radius: 5px;
    margin-top: 10px;
}

label.fw-medium {
    cursor: pointer;
}

label.fw-medium span {
    cursor: pointer;
    padding: 8px 12px;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    display: inline-block;
}

label.fw-medium span.btn-secondary {
    background-color: #6c757d;
}

label.fw-medium span:hover {
    background-color: #0056b3;
}

label.fw-medium span.btn-secondary:hover {
    background-color: #5a6268;
}
/* Style for the image upload section */
.upload-image-container {
    margin-top: 20px;
}

.upload-image-container .form-label {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 10px;
}

.upload-image-container .form-control {
    margin-bottom: 10px;
}

/* Style for the image preview card */
.card-image-preview {
    max-width: 100%;
    height: auto;
    overflow: hidden;
    margin-top: 15px;
}

.card-image-preview .card-img {
    width: 100%;
    height: auto;
    object-fit: cover; /* Ensures image fits nicely */
}

.card-image-preview .card-body {
    padding: 10px;
}

.card-image-preview .card-title {
    font-size: 1rem;
    font-weight: 500;
    color: #333;
}

.productmain-images{
    width: 300px;
}
/* In addproduct.css or your relevant stylesheet */

/* Spinner overlay for full-screen centering */
.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* Ensure it’s above other content */
}

/* Spinner container styles */
.spinner-container {
    display: flex;
    align-items: center;
}

/* Spinner balls styling */
.spinner-container .ball {
    --size: 16px;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    margin: 0 10px;
    background-color: #4285f5; /* Default color */
    animation: 2s bounce ease infinite;
}

.spinner-container .blue {
    background-color: #4285f5;
}

.spinner-container .red {
    background-color: #ea4436;
    animation-delay: 0.25s;
}

.spinner-container .yellow {
    background-color: #fbbd06;
    animation-delay: 0.5s;
}

.spinner-container .green {
    background-color: #34a952;
    animation-delay: 0.75s;
}

@keyframes bounce {
    50% {
        transform: translateY(25px);
    }
}
