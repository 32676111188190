

.login-image-col {
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

.login-card {
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.login-card .card-title {
    font-size: 1.5rem;
    font-weight: normal;
    color: #000!important;
    margin-bottom: 20px;
}

.login-card .form-label {
    font-size: 1rem;
    font-weight: 500;
}

.login-card .form-control {
    border-radius: 5px;
    padding: 10px;
}

.login-card .btn {
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
}

.login-card .btn:hover {
    background-color: #0056b3;
}

.login-card .text-center a {
    color: #007bff;
    text-decoration: none;
}

.login-card .text-center a:hover {
    text-decoration: underline;
}

.card {
    position: relative;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    color: #fff!important;
    background-clip: border-box;
    border: none!important;
    border-radius: 0.25rem;
}


.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px !important;
    pointer-events: auto;
    background-color: #333!important;
    background-clip: padding-box;
    border: 1px solid #2c2e33;
    border-radius: 0.3rem;
    outline: 0;
}
.otp-form-group {
    display: flex;

    gap: 10px; /* Space between input fields */
    margin: 0px 0 10px 0;
  }
  
  .otp-input {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 24px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .otp-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .otp-input[type="number"] {
    -moz-appearance: textfield;
    
  }
  .custom_model .modal-header {
    padding: 0px; /* Adjust the padding to decrease height */
    height: 0px; /* Remove the minimum height if set */
  }
  /* .container{
    background-color: #fff;
    
    } */
    .custom_model .btn-close {
        color: #ff0000; /* Change the color of the "X" */
  background-color: transparent; /* Ensure the background is transparent */
  border: none; /* Remove any default border */
  position: absolute;
  margin-left: 650px;
  margin-top: 40px;
  z-index: 1000000000000000;
      }
      
      .custom_model .btn-close:hover {
        color: #cc0000; /* Change color on hover */
      }
      
      .custom_model .btn-close:focus {
        box-shadow: 0 0 0 0.25rem #333(0, 123, 255, 0.5); /* Custom focus shadow */
      }
      .modal-body{padding: 0px}

      .dvotp{
       display: none; 
      }
      @media (min-width: 992px) {
        .modal-lg {
          width: 700px!important;
        }
      }