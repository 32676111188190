.container {
    margin-top: 20px;
}


.product-list{
    margin-top: 4rem;
    background-color: #000000;
    width: 100%;
}
.product-list-image {
    width: 0px;
    height: 100px;
}

.table th, .table td {
    vertical-align: middle;
    text-align: center;
    border-color: #dee2e6 !important; /* Light gray border color */
    /* background-color:#000000 !important; Ensure white background for all cells */
}

.table {
    border-color: #dee2e6 !important; /* Light gray border color */
}

.table-hover tbody tr:hover {
    background-color:#000000 !important; /* Light gray on hover */
}

.action-button {
    margin: 0 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary:hover {
    background-color: #0056b3;
    border-color: #004085;
}

.btn-danger {
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-danger:hover {
    background-color: #c82333;
    border-color: #bd2130;
}

h2.primary {
    color: #007bff;
    text-align: center;
    margin-top: 20px;
}

/* productlist.css */
td.product-name {
    word-wrap: break-word;
    white-space: normal;
    max-width: 150px;
    overflow-wrap: break-word;
    vertical-align: middle;
}
