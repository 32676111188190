/* adminheader.css */

/* Navbar */
.navbar {
   
}

.navbar-brand {
    margin-right: 1rem;
}

/* Search Form */
.search {
    width: 86%;
}

/* Profile Dropdown */
.navbar-profile {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: left;
}

.navbar-profile-name {
    margin-right: 1rem;
}

/* Dropdown Menu */
.dropdown-menu {
    width: 240px; /* Adjust the width as needed */
    padding: 0;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add a box shadow for depth */
}

.dropdown-item {
    padding: 0.5rem 1rem;
}

.dropdown-item:hover {
    background-color: #f8f9fa; /* Add a background color on hover */
}

/* Notification Dropdown */
#notificationDropdown .count {
    position: relative;
    top: -8px;
    right: -8px;
    font-size: 0.75rem;
    font-weight: bold;
    color: #ffffff;
    
    border-radius: 50%;
    padding: 4px 6px;
}

/* Divider */
.dropdown-divider {
    margin: 0.5rem 0;
    border-top: 1px solid #dee2e6; /* Add a border color for the divider */
}

/* Centered Text */
.text-center {
    text-align: center;
}

/* Bell Icon */
#notificationDropdown .fa-bell {
    font-size: 1.5rem;
}

/* Profile Icon */
#profileDropdown .fa-user-circle {
    font-size: 1.5rem;
}

/* Sidebar Toggle Icon */
#sidebarToggle {
    cursor: pointer;
}
/* Style for the search container */
.search-container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

/* Style for the search input */
.search-container input[type="text"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 60px;
    width: 380px; /* Adjust width as needed */
    box-sizing: border-box; /* Ensures padding is included in width */
}

/* Style for the search button */
.search-container button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    border: none;
    background: #f2f2f2;
    cursor: pointer;
    border-radius: 0 4px 4px 0;
}

/* Style for the search icon */
.search-container button i {
    font-size: 18px;
    color: #666;
}

/* Hover effect for the search button */
.search-container button:hover {
    background: #ddd;
}
