/* Comboproduct.css */

.container-fluid.comboproduct {
    margin-top: 80px;
    padding: 40px;
    background-color: #000000;
    border-radius: 0px;
    
}
.combo-list{
    margin-top: 89px;
}
.container-fluid.comboproduct h2 {
    margin-bottom: 20px;
    color: #f4f4f4;
}

.form-group {
    margin-bottom: 20px;
}



.form-control {
    border-radius: 5px;
    /* padding: 20px; */
}

.form-check-label {
    font-weight: normal;
}

.btn-primary {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
}

.btn-primary:hover {
    background-color: #0056b3;
}
