.editproduct .form-label{
    color: #f4f4f4
}
.editproduct-container{
    background-color: #000000;
}


.product-list-images {
    height: 200px;
}
