/* Container for the form */
  
  /* Labels */
  label {
    font-weight: 500;
    color: #495057; /* Dark grey for readability */
  }
  
  /* Input fields */
  .form-control {
    border: 2px solid #ced4da;
    border-radius: 5px;
    transition: border-color 0.3s;
  }
  
  .form-control:focus {
    border-color: #007bff; /* Blue outline on focus */
    box-shadow: none;
  }
  
  /* Submit Button */
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    font-size: 16px;
    padding: 10px 20px;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .btn-primary:hover {
    background-color: #0056b3; /* Darker blue on hover */
    transform: translateY(-2px); /* Slight lift effect */
  }
  
  
  /* Media Queries for Responsive Design */
  @media (max-width: 768px) {
    .addproduct-container {
      max-width: 100%;
      padding: 15px;
    }
  }
  