.create-hsn-container {
    background: #191c24; /* Your specified background color */
    min-height: 100vh;
    padding: 50px 0;
    font-family: Arial, sans-serif;
  }
  
  .rounded {
    border-radius: 10px;
  }
  
  .shadow {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  }
  
  .input-field {
    border-radius: 5px;
    border: 1px solid #ced4da;
    transition: border-color 0.3s;
  }
  
  .input-field:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
  }
  
  .btn-submit {
    background-color: #007bff; /* Primary button color */
    border: none;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .btn-submit:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  
  /* Additional styling for the form container */
  .form-container {
    background-color: #2c3037; /* Slightly lighter background for contrast */
    padding: 20px;
    border-radius: 10px;
  }
  